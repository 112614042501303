<template>
  <section class="max-w-3xl m-auto">
    <div class="mb-8">
      <p class="font-cooper text-brand-black font-normal lg:text-4xl text-2xl">Job Preferences</p>
      <p class="text-brand-black/40 text-base font-normal">We can help you find what you’re looking for.</p>
    </div>
    <div>
      <div class="mb-6">
        <Select
          id="role"
          label="What point are you in your job search?"
          :options="enums.candidate_availability_statuses"
          v-model="preferenceDetails.availability_status"
        />
      </div>
      <div class="mb-6">
        <label for="role" class="block text-lg font-albert text-brand-black/70 mb-3">What type of role are you looking for?</label>
        <div class="grid gap-4 md:grid-cols-4 sm:grid-cols-2">
          <div class="inline-flex gro mr-2" v-for="item in enums.job_types" :key="item.value">
            <input
              :id="item.value"
              type="checkbox"
              :value="item.key"
              v-model="preferenceDetails.job_types"
              class="w-6 h-6 text-brand-primary mb-2 bg-brand-light/10 rounded-[10px] p-3 appearance-none focus:outline-none border border-brand-light focus:border-brand-primary focus:border focus:ring-brand-primary"
            />
            <label :for="item.value" class="ml-2 text-brand-black/70 text-lg whitespace-nowrap">{{ item.value }}</label>
          </div>
        </div>
      </div>
      <div class="mb-6">
        <Input
          label="How many years of experience do you have?"
          labelFor="experience"
          type="text"
          id="experience"
          placeholder="10"
          v-model="preferenceDetails.years_of_experience"
        />
      </div>
      <div class="mb-6">
        <Select
          id="type"
          label="What type of company are you looking to work for?"
          :options="companyCategory"
          v-model="preferenceDetails.company_category"
        />
      </div>
      <div class="mb-6">
        <label for="qualification" class="block text-lg font-medium text-brand-black/70">Where would you like to work in?</label>
        <div class="grid gap-4 md:grid-cols-2">
          <Select id="Country" :options="country" v-model="preferenceDetails.location.country" />
          <Select id="State" :options="state" v-model="preferenceDetails.location.state" />
        </div>
      </div>
      <div class="mb-6">
        <div class="mb-3">
          <label for="" class="text-lg text-gray-500">What are you looking for in your next role</label>
        </div>
        <div class="lg:flex flex-wrap mb-5">
          <template v-for="(preference, index) in perks_options" :key="index">
            <input type="checkbox" :id="'_' + index" :value="preference" class="hidden peer perk-input" required="" v-model="selectedPerks" />
            <label :for="'_' + index" class="perk p-3 mr-2 mb-2">
              {{ preference }}
            </label>
          </template>
        </div>
      </div>

      <div class="mb-6">
        <label for="Currency" class="block text-lg font-medium text-brand-black/70 mb-3">Salary currency</label>
        <Select id="Currency" :options="enums.currencies" v-model="preferenceDetails.desired_salary_currency" />
      </div>

      <div class="mb-10">
        <label for="salary" class="block text-lg font-medium text-brand-black/70 mb-3">Desired salary</label>
        <input
          id="salary"
          type="text"
          v-model="preferenceDetails.desired_salary"
          class="bg-brand-light/10 text-base rounded-[10px] w-full p-4 appearance-none focus:outline-none border border-brand-light focus:border-brand-dark focus:border focus:ring-1"
          placeholder="Enter amount"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import Select from '@/components/form/Select.vue';
import Input from '@/components/form/Input.vue';
import TextArea from '@/components/form/TextArea.vue';
import { useStore } from 'vuex';
import { errorMessage } from '@/utils/helper';

const store = useStore();
const state = ref(null);
const companyCategory = ref(null);
const pageLoaded = ref(false);
const selectedPerks = ref([]);

const preferenceDetails = ref({
  availability_status: '',
  job_types: [],
  years_of_experience: '',
  company_category: '',
  location: {
    country: '',
    state: '',
  },
  next_role_perks: '',
  desired_salary: '',
  desired_salary_currency: '',
});

const perks_options = ref([
  'More Money',
  'Flexibility and Wellbeing',
  'Working with great people',
  'Innovative product',
  'Remote Work',
  'Great tech and tools',
  'Recognition and reward',
  'Development and progression',
  'Diversity and Inclusion',
]);

const enums = computed(() => {
  return store.state.global.EnumDetails;
});

const country = computed(() => {
  return store.state.global.Countries;
});

watch(
  () => preferenceDetails.value.location.country,
  async (country) => {
    try {
      state.value = await store.dispatch('global/getStates', country);
    } catch (error) {
      toast.error(errorMessage(error), {
        timeout: 3000,
        hideProgressBar: true,
      });
    }
  },
);

watch(
  () => selectedPerks.value,
  (perks) => {
    preferenceDetails.value.next_role_perks = perks;
  },
);

onMounted(async () => {
  getCompanyCategories();
});

const getCompanyCategories = async () => {
  try {
    companyCategory.value = await store.dispatch('global/getCompanyCategory');
  } catch (error) {
    toast.error(errorMessage(error), {
      timeout: 3000,
      hideProgressBar: true,
    });
  } finally {
    pageLoaded.value = true;
  }
};

defineExpose({
  preferenceDetails,
});
</script>
